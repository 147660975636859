<template>
  <section>
    <Cards />
    <b-tabs
      content-class="mt-1"
      align="center"
    >
      <b-tab title="Assinaturas">
        <b-card>
          <subscriptions />
        </b-card>
      </b-tab>
      <b-tab title="Pagamentos">
        <b-card>
          <payments />
        </b-card>
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import {
  BRow, BCol, BTabs, BTab, BCard
} from 'bootstrap-vue'
import Cards from './Cards.vue'
import Subscriptions from './Subscriptions/Index.vue'
import Payments from './Payments/Index.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,
    Cards,
    Subscriptions,
    Payments
  }
}
</script>
