export default {
  data: () => ({
    loading: true,
    createModal: false,
    editModal: false,
    item: {},
    fields: [
      {
        key: 'email',
        label: 'email'
      },
      {
        key: 'price',
        label: 'valor'
      },
      {
        key: 'plan_name',
        label: 'Plano'
      },
      {
        key: 'created_at',
        label: 'Data'
      }
    ],
    items: []
  }),
  methods: {
    async getSubscriptions () {
      const url = '/admin/verificador_infinity/payments'

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async mounted () {
    await this.getSubscriptions()
  }
}
