<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      :width="selected === 3 ? '800px' : ''"
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Criar
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <vue-autosuggest
              id="email"
              ref="autocomplete"
              v-model="query"
              :suggestions="suggestions"
              :get-suggestion-value="getSuggestionValue"
              :input-props="inputProps"
              @input="onInputChange"
              @selected="onSelected"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.email }}
              </div>
            </vue-autosuggest>
          </b-form-group>

          <b-form-group
            label="Data de Vencimento"
            label-for="due_date"
          >
            <DateTimePicker
              v-model="formData.due_date"
              :render="renderDatetimePicker"
              :display-current-date="true"
              :config="config"
              locale="pt"
              alt-timezone="America/Sao_Paulo"
              date-timezone="UTC"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import AutoSuggestMixin from './mixins/AutoSuggestMixin'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  components: {
    VueAutosuggest,
    DateTimePicker,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton
  },
  mixins: [AutoSuggestMixin],
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    renderDatetimePicker: false,
    created_item: {},
    formData: {
      due_date: ''
    },
    config: {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i'
    }
  }),
  mounted () {
    this.renderDatetimePicker = true
  },
  methods: {
    clearForm () {
      this.formData = {
        due_date: ''
      }
    },
    async handleSubmit () {
      const url = '/admin/verificador_infinity/subscriptions'
      const params = {
        due_date: this.formData.due_date,
        user_id: this.selected.id
      }

      this.$http
        .post(url, params)
        .then(res => {
          this.created_item = res.data
        })
        .finally(() => {
          this.$emit('updated', this.created_item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Criado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
