<template>
  <section>
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="transparent"
      opacity="1"
      blur="2px"
      rounded="sm"
    >
      <b-card>
        <b-card-body>
          <b-card-title>
            <b-button
              variant="relief-primary"
              class="float-right"
              @click.stop="createModal = true"
            >
              Adicionar
            </b-button>
          </b-card-title>
        </b-card-body>
        <b-row>
          <b-col
            md="3"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-inline-block text-sm-left mr-50"
              >Por Página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-25"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            sm="8"
            class="my-1"
          >
            <b-form-group
              label="Ordenar Por"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="sortBySelect"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      -- Nenhum --
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="my-1"
          >
            <b-form-group
              label="Buscar"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Escreva o que deseja pesquisar."
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              ref="selectableTable"
              striped
              hover
              small
              sticky-header
              no-border-collapse
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-selected="onRowSelected"
            >
              <template #cell(email)="data">
                {{ data.item.user.email }}
              </template>

              <template #cell(price)="data">
                {{ data.item.price | currency }}
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | date }}
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BTable,
  BButton,
  BSpinner,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import IndexMixin from './mixins/IndexMixin'
import TableMixin from './mixins/TableMixin'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BTable,
    BButton,
    BSpinner,
    VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    },
    currency (val) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(val)
    }
  },
  mixins: [IndexMixin, TableMixin]
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
