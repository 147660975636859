export default {
  data: () => ({
    query: '',
    selected: '',
    suggestions: [{
      data: []
    }],
    inputProps: {
      id: 'autosuggest__input_ajax',
      class: 'form-control',
      placeholder: 'Digite o email'
    },
    timeout: null,
    debounceMilliseconds: 250
  }),
  methods: {
    onSelected (item) {
      this.selected = item.item
    },
    onInputChange () {
      const { query } = this

      if (query.length === 0) {
        return
      }

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const url = `/admin/users/paginate/5?page=1&filterBy=email&filter=${query}`
        this.$http.get(url)
          .then(res => {
            this.suggestions[0].data = res.data.data
          })
      }, this.debounceMilliseconds)
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue (suggestion) {
      return suggestion.item.email
    }
  }
}
