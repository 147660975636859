import {
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton
  },
  data: () => ({
    showTable: true,
    perPage: 10,
    pageOptions: [3, 5, 10, 25, 50, 100, 250, 500, 1000, 2000, 5000],
    totalRows: 1,
    currentPage: 1,
    sortBy: 'id',
    sortDesc: true,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    infoModal: {
      id: 'info-modal',
      title: '',
      content: ''
    },
    selected: []
  }),
  watch: {
    items () {
      this.updateRows()
    }
  },
  computed: {
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    }
  },
  methods: {
    onRowSelected (items) {
      this.selected = items
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.selected = []
      this.$refs.selectableTable.clearSelected()
    },
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    info (item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal () {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    updateRows () {
      this.totalRows = this.items.length
    }
  }
}
