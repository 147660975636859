<template>
  <section>
    <b-row class="match-height">
      <b-col
        lg="6"
        sm="12"
      >
        <statistic-card-with-area-chart
          icon="perfil.png"
          :statistic="kFormatter(items.total_amount_in_the_last_month)"
          statistic-title="Vendas do mês anterior"
          :chart-data="[
            { name: '', data: [28, 40, 36, 52, 38, 60, 55] },
          ]"
        />
      </b-col>

      <b-col
        lg="6"
        sm="12"
      >
        <statistic-card-with-area-chart
          icon="money-bag.png"
          color="warning"
          :statistic="kFormatter(items.total_amount_in_this_month)"
          statistic-title="Vendas deste mês"
          :chart-data="[
            { name: '', data: [28, 40, 36, 52, 38, 60, 55] },
          ]"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from './StatisticCardWithAreaChart.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardWithAreaChart
  },
  data: () => ({
    items: []
  }),
  async mounted () {
    await this.getTotalAmount()
  },
  methods: {
    kFormatter,
    async getTotalAmount () {
      const url = '/admin/verificador_infinity/total_amount'

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
    }
  }
}
</script>
