<template>
  <flat-pickr
    v-if="!loading && render"
    v-model="dateTime"
    class="form-control"
    :config="autoConfig"
    placeholder="Selecione uma data"
    @change="(val) => update(val)"
  />
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    flatPickr
  },
  model: {
    prop: 'item',
    event: 'update:item'
  },
  props: {
    item: {
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    },
    dateTimezone: {
      type: String,
      required: true
    },
    altTimezone: {
      type: String,
      required: true
    },
    render: {
      type: Boolean,
      default: false
    },
    displayCurrentDate: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    loading: true,
    dateTime: '',
    timezone: {
      'America/Sao_Paulo': '-03:00',
      UTC: '+00:00'
    }
  }),
  computed: {
    autoConfig () {
      return {
        ...this.config,
        locale: require(`flatpickr/dist/l10n/${this.locale}`).default[
          this.locale
        ]
      }
    }
  },
  watch: {
    render () {
      this.prepareToRender()
    },
    dateTime () {
      if (!this.loading) {
        this.update(this.dateTime)
      }
    }
  },
  mounted () {
    this.prepareToRender()
  },
  methods: {
    prepareToRender () {
      if (this.render === false) {
        return
      }

      if (this.loading && this.displayCurrentDate) {
        this.dateTime = moment().format('Y-MM-DD HH:mm:ss')
        this.update(this.dateTime)
        this.loading = false
        return
      }

      if (this.loading && (this.item === '' || this.item === null)) {
        this.dateTime = ''
        this.update(this.dateTime)
        this.loading = false
        return
      }

      if (this.loading && (this.item !== '' || this.item !== null)) {
        const dateTime = moment.utc(this.item).utcOffset(this.timezone[this.altTimezone])
        this.dateTime = dateTime.format('Y-MM-DD HH:mm:ss')
        this.update(this.dateTime)
        this.loading = false
      }
    },
    update (val) {
      if (val.length === 0) {
        this.$emit('update:item', null)
        return
      }

      const dateTime = moment(val).utcOffset(this.timezone[this.dateTimezone])
      this.$emit('update:item', moment(dateTime).format('Y-MM-DD HH:mm:ss'))
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
