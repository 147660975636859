export default {
  data: () => ({
    loading: true,
    createModal: false,
    editModal: false,
    item: {},
    fields: [
      {
        key: 'email',
        label: 'email'
      },
      {
        key: 'created_at',
        label: 'Criado em'
      },
      {
        key: 'status',
        label: 'status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ],
    items: []
  }),
  methods: {
    async getSubscriptions () {
      const url = '/admin/verificador_infinity/subscriptions'

      await this.$http.get(url)
        .then(res => {
          this.items = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    open (id) {
      this.item = JSON.parse(
        JSON.stringify(this.items.find(item => item.id === id))
      )
      this.editModal = true
    },
    async updateStatus (id) {
      const status = this.items.find(item => item.id === id)
      status.active = status.active === 0 ? 1 : 0
      const params = {
        active: status.active
      }

      await this.$http.patch(`/admin/verificador_infinity/subscriptions/${status.id}`, params)
    },
    created (payload) {
      this.items.unshift(payload)
    },
    updated (payload) {
      Object.assign(
        this.items.find(item => item.id === payload.id),
        payload
      )
    },
    deleteModal (item) {
      this.$swal({
        title: `Você tem certeza que deseja deletar a Opção "${item.name}"?`,
        text: 'Essa ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, deletar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.delete(item.id)
        }
      })
    },
    async delete (id) {
      this.loading = true
      const url = `/admin/verificador_infinity/subscriptions/${id}`
      await this.$http
        .delete(url)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'A Opção selecionada foi deletada com sucesso!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })

          this.items = this.items.filter(item => item.id !== id)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  async mounted () {
    await this.getSubscriptions()
  }
}
