<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:visible', val)"
    >
      <template
        #default="{ hide }"
      >
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Editar
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Data de Vencimento"
            label-for="due_date"
          >
            <DateTimePicker
              v-model="item.due_date"
              :render="renderDatetimePicker"
              :config="config"
              locale="pt"
              alt-timezone="America/Sao_Paulo"
              date-timezone="UTC"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    DateTimePicker
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: true,
    renderDatetimePicker: false,
    getLocale: 'pt',
    datetime: '',
    config: {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i'
    }
  }),
  mounted () {
    this.renderDatetimePicker = true
  },
  methods: {
    handleSubmit () {
      const url = `/admin/verificador_infinity/subscriptions/${this.item.id}`
      const params = {
        due_date: this.item.due_date
      }

      this.$http.patch(url, params)
        .finally(() => {
          this.$emit('updated', this.item)
          this.$emit('update:visible', false)

          this.$swal({
            title: 'Atualizado com sucesso!!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
            },
            buttonsStyling: false
          })
        })
    }
  }
}
</script>
